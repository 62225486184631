<section class="head_section head_section_normal" id="desktop_view_header" *ngIf="url != 'live-interview'">
	<div class="nav_head">
		<div class="left">
			<a class="navbar-brand custom_brand" routerLink="/" *ngIf="!(isLogin && isOnlyForSupport)">
				<img src="./assets/images/astrotalk_logo_pink.png" alt="Astrotalk-logo" class="img-fluid" width="1851" height="400">
			</a>
			<a class="navbar-brand custom_brand" routerLink="/support-chat" *ngIf="isLogin && isOnlyForSupport">
				<img src="./assets/images/astrotalk_logo_pink.png" alt="Astrotalk-logo" class="img-fluid" width="1851" height="400">
			</a>
		</div>
		<div class="right" *ngIf="!isMobile">
			<div class="buttons">
				<div class="ml-auto d-flex justify_content">
					<div class="mt-2" *ngIf="showPermission && isLogin" routerLink="/allow-notifications">
						<marquee scrollamount="3" onmouseover="this.stop();" onmouseout="this.start();"
										 class="notification_allow"><span class="fa fa-info-circle"></span>
							Please allow notifications from your browser settings. <u>Click here to know more.</u>
						</marquee>
					</div>
					<div class="note" *ngIf="isLogin && languages"><b>Regional languages:</b> {{languages}}</div>
					<a routerLink="/support-chat" *ngIf="isLogin" class="login_btn support" matTooltip="Support Chat">
						<img src="https://d1gcna0o0ldu5v.cloudfront.net/fit-in/20x20/assets/images/mobile_modal_header/webp_images/support_icon.webp" alt="support_icon" width="25" height="25">
					</a>
					<div class="contact_btn btnn btn" *ngIf="!isLogin">
						<a id="loginButton"><i class="fas fa-user"></i> login</a>
					</div>
					<div class="contact_btn btnn btn" *ngIf="isLogin && isOnlyForSupport">
						<a (click)="logout()"><i class="fas fa-user"></i> LogOut</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<!-- new dashboard -->
<section class="new_dashboard" [ngClass]="{'dynamicClassDashboard' : isLogin && url != 'live-interview',
 'dynamicClassDashboardLiveRoom': url == 'live-interview'}">
  <div class="left_side_menu header-side-menu" *ngIf="isLogin && !isOnlyForSupport && url != 'live-interview'">
    <div class="profile_details">
      <div class="profile_picture" [routerLink]="'./profile'">
        <img [src]="astroData.profile_pic ? astroData.profile_pic: './assets/images/astrolger/profile_pic.png'"
             alt="avatar">
        <i aria-hidden="true" class="fa fa-edit edit_profile_pic_icon"></i>
      </div>
      <div class="name_astrologer" *ngIf="astroData?.firstname">{{astroData.firstname}} {{astroData?.lastname}}</div>
      <div class="mobile_astrologer" *ngIf="astroData?.mobileno">
        <span>{{astroData?.countrycode}}-{{astroData?.mobileno}}</span></div>
    </div>
    <div class="navbar-nav" [ngClass]="{'active': urlIncludes == '/dashboard'}" [routerLinkActive]="['active']">
      <a class="nav-link single_item_link" [routerLink]="['/dashboard']">Dashboard</a>
    </div>
    <div class="navbar-nav menu_options_astrologer">
      <div class="dropdown">
        <a [class.show]="urlIncludes == '/call-history' || urlIncludes == '/chat-history' || urlIncludes == '/report-history' || urlIncludes == '/astromall-history'" class="nav-link dropdown-toggle" href="#" role="button" id="navbarDropdown3" data-bs-toggle="dropdown" aria-expanded="false"> Order History </a>
        <ul class="dropdown-menu" [class.show]="urlIncludes == '/call-history' || urlIncludes == '/chat-history' || urlIncludes == '/report-history' || urlIncludes == '/astromall-history'" aria-labelledby="navbarDropdown3">
          <li>
            <a class="dropdown-item" [ngClass]="{'active': urlIncludes == '/call-history' || urlIncludes == '/call-to-user'}"
               [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: false}" routerLink="/call-history"> Call History</a>
          </li>
          <li>
            <a class="dropdown-item" [ngClass]="{'active': urlIncludes == '/chat-history' || urlIncludes == '/chat-with-user'}"
               [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: false}" routerLink="/chat-history"> Chat History</a>
          </li>
          <li>
            <a class="dropdown-item" [ngClass]="{'active': urlIncludes == '/report-history'}"
               [routerLinkActive]="['active']" routerLink="/report-history"> Report History</a>
          </li>
          <li><a class="dropdown-item" [ngClass]="{'active': urlIncludes == '/astromall-history'}"
                 [routerLinkActive]="['active']" routerLink="/astromall-history"> Astromall History</a></li>
        </ul>
      </div>
      <div class="navbar-nav" [ngClass]="{'active': urlIncludes == '/wallet'}" [routerLinkActive]="['active']">
        <a class="nav-link single_item_link" routerLink="/wallet">Wallet</a>
      </div>
      <div class="navbar-nav" [ngClass]="{'active': urlIncludes == '/offers'}" [routerLinkActive]="['active']">
        <a class="nav-link single_item_link" routerLink="/offers">Offers</a>
      </div>
      <div class="navbar-nav" [ngClass]="{'active': urlIncludes == '/remedies'}" [routerLinkActive]="['active']">
        <a class="nav-link single_item_link" routerLink="/remedies">Remedies</a>
      </div>
      <div class="navbar-nav" [ngClass]="{'active': urlIncludes == '/wait-list'}" [routerLinkActive]="['active']">
        <a class="nav-link single_item_link" routerLink="/wait-list">Wait List</a>
      </div>
      <div class="navbar-nav" [ngClass]="{'active': urlIncludes == '/my-reviews'}" [routerLinkActive]="['active']">
        <a class="nav-link single_item_link" routerLink="/my-reviews">My Reviews</a>
      </div>
      <div class="navbar-nav" [ngClass]="{'active': urlIncludes == '/in-mail'}" [routerLinkActive]="['active']">
        <a class="nav-link single_item_link" routerLink="/in-mail">InMail</a>
      </div>
			<!--<div class="navbar-nav" [ngClass]="{'active': urlIncludes == '/live-event'}" [routerLinkActive]="['active']">
				<a class="nav-link single_item_link" routerLink="/live-event">Live Events</a>
			</div>-->
			<div class="navbar-nav" [ngClass]="{'active': urlIncludes == '/my-followers'}" [routerLinkActive]="['active']">
				<a class="nav-link single_item_link" routerLink="/my-followers">My Followers</a>
			</div>
			<div class="navbar-nav" [ngClass]="{'active': urlIncludes == '/loyal-club-membership'}" [routerLinkActive]="['active']">
				<a class="nav-link single_item_link" routerLink="/loyal-club-membership">Loyal Club Members</a>
			</div>
      <div mdbDropdown class="dropdown">
        <a mdbDropdownToggle [class.show]="urlIncludes == '/support-chat' || urlIncludes == '/chat-with-relationship-manager' || urlIncludes == '/assistant-chat'" class="nav-link dropdown-toggle" href="#" role="button" id="supportChat" data-bs-toggle="dropdown" aria-expanded="false"> Support Chat </a>
        <ul mdbDropdownMenu class="dropdown-menu" [class.show]="urlIncludes == '/support-chat' || urlIncludes == '/chat-with-relationship-manager' || urlIncludes == '/assistant-chat'" aria-labelledby="supportChat">
          <li>
            <a class="dropdown-item" [ngClass]="{'active': urlIncludes == '/support-chat'}"
               [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: false}" routerLink="/support-chat"> Customer Support</a>
          </li>
          <li>
            <a class="dropdown-item" [ngClass]="{'active': urlIncludes == '/assistant-chat'}"
               [routerLinkActive]="['active']" routerLink="/assistant-chat"> Chat with own Assistant</a>
          </li>
        </ul>
      </div>
      <div class="navbar-nav" [ngClass]="{'active': urlIncludes == '/settings'}" [routerLinkActive]="['active']">
        <a class="nav-link single_item_link" routerLink="/settings">Settings</a>
      </div>
			<div class="navbar-nav" [ngClass]="{'active': urlIncludes == '/mandatory-warnings'}" [routerLinkActive]="['active']">
				<a class="nav-link single_item_link" routerLink="/mandatory-warnings">Mandatory Warnings</a>
			</div>
      <div class="navbar-nav" *ngIf="astroData?.consultantTypeId == 5" [ngClass]="{'active': urlIncludes == '/astrologer-interview'}" [routerLinkActive]="['active']">
        <a class="nav-link single_item_link" routerLink="/astrologer-interview">Astrologer Interview</a>
      </div>
    </div>
  </div>
  <div class="right_side_display" id="scrollingToTop">
    <router-outlet></router-outlet>
  </div>
</section>
<!-- new dashboard -->

