import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {CommonService} from '../services/common.service';
import {HttpWrapperService} from '../services/http-wrapper.service';
import { map, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable()
export class AuthGuardService implements CanActivate{
  constructor(private router: Router, public common: CommonService, public _http: HttpWrapperService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		const token = (this.common.getToken()) ? true : false;
		const user = (this.common.getAstroData('id')) ? true : false;
		if (token && user) {
			return this._http.verifyToken(true).pipe(
				map(
					(res: any) => {
						if (res.status == 'success' && res.reason != 'token not expired') {
							const isDeleted = this.common.getItem('isDeleted');
							const isOpenForSupportWithSdOff = this.common.getItem('isOpenForSupportWithSdOff');
							if(isDeleted == 'true' && isOpenForSupportWithSdOff == 'true') {
								if(state.url != '/support-chat' && state.url != '/') {
									this.router.navigate(['/support-chat']);
								}
								return true;
							}else{
								this.logout();
								return false;
							}

						} else {
							return true;
						}
					}
				),
				catchError(() => {
					return of(false);
				})
			);
		} else {
			this.logout();
			return false;
		}
  }

	logout() {
		this.common.logout();
		this.router.navigate(['/']);
	}
}
